import React from "react";
import FadeContent from "../../utils/FadeContent";
import "./style.css";

const PageTitle = (props) => {
  console.log("props", props);
  return (
    <section
      className={props?.title ? "breadcrumb-new" : "breadcrumb-area"}
      style={{
        backgroundImage: `url(${props.pageImg})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inn">
              <FadeContent
                blur={true}
                duration={1000}
                easing="ease-out"
                initialOpacity={0}
              >
                <h1>{props.PageTitle}</h1>
              </FadeContent>
              <FadeContent
                blur={true}
                duration={1000}
                easing="ease-out"
                initialOpacity={0}
              >
                <h1 className="syl-retreat-title text-capitalize">
                  {props?.project_page_syl && props?.project_page_syl}
                </h1>
              </FadeContent>
              <FadeContent
                blur={true}
                duration={1000}
                easing="ease-out"
                initialOpacity={0}
              >
                <h2 className="subtitle text-capitalize">
                  {props?.subtitle && props?.subtitle}
                </h2>
                <h2 className="subtitle">
                  {props?.intSubtitle && props?.intSubtitle}
                </h2>
              </FadeContent>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
